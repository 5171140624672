<template>
  <Layout>
    <div>
      <div class="container-fluid pt-5">
        <div class="title mb-4">
          New Application
        </div>
        <btn-back/>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="header mb-2">
              Get an estimate on your ERC amount
            </div>
            <div class="body mb-2">
              <div class="title mb-2">
                Personal Information
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-2">
                    <label for="name">Name*</label>
                    <input class="form-control" id="name" v-model="this.name"
                           :class="{'is-invalid': submitted && v$.name.$error,}"
                           type="text" placeholder="Type in...">
                    <div v-for="(item, index) in v$.name.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div>
                    <label for="email">Email*</label>
                    <input class="form-control" id="email" type="text"
                           :class="{'is-invalid': submitted && v$.email.$error,}"
                           v-model="this.email" placeholder="Type in...">
                    <div v-for="(item, index) in v$.email.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                    <div class="red-color">
                      <span v-show="this.isUpperCase">Email must be lowercase.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-2">
                    <label for="user_title">User Title*</label>
                    <input class="form-control" id="user_title" v-model="this.user_title"
                           :class="{'is-invalid': submitted && v$.user_title.$error,}"
                           type="text" placeholder="Type in...">
                    <div v-for="(item, index) in v$.user_title.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div>
                    <label for="mobile_no">Mobile No.</label>
                    <input class="form-control" id="mobile_no"
                           :class="{'is-invalid': submitted && v$.mobile_no.$error || !isValidPhoneNumber}"
                           v-model="this.mobile_no" type="text" placeholder="(000)000 0000">
                    <div v-for="(item, index) in v$.mobile_no.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                    <div class="red-color">
                      <span v-if="!isValidPhoneNumber">Phone number is not valid!</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <BootStrapAlert/>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="header mb-2 d-flex justify-content-between">
              <span>Add Company</span>
              <span @click="toggleBtn" class="btn-toggle" :class="this.isPlus ? 'plus': 'minus'"></span>
            </div>

            <div v-show="this.added_companies.length">
              <div class="added-company-item mb-2 pt-4" v-for="(company, index) in this.added_companies" :key="index">
                <div class="d-flex justify-content-between">
                  <span class="company-name">{{ company.name }}</span>
                  <span class="btn-delete" @click="onItemCompanyDeleted(index)">Delete</span>
                </div>
                <div class="amount">Potential ERC amount: {{ this.usDollar(company.erc_amount) }}</div>
                <div class="amount">Possible PPP Loan: {{ this.usDollar(company.ppp_loan) }}</div>
              </div>
            </div>

            <div class="body mb-2" :class="this.isPlus ? 'collapse' : ''">
              <div class="title mb-2">
                Company Information
              </div>
              <div class="row mb-3">
                <div class="col-md-12 mt-2 company-name-input">
                  <label for="company_name">Company Name *</label>
                  <div>
                    <input class="form-control" ref="company_name" v-model="this.company.name"
                           :class="{'is-invalid': submitted && v$.company.name.$error,}"
                           @input="updateCompanyName" id="company_name" type="text" placeholder="Type in...">
                    <div v-for="(item, index) in v$.company.name.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                  <div class="list-group mt-1 company-name-drop-down-list" v-show="this.companies.length">
                    <button v-for="(company, index) in this.companies" @click="onItemCompanyClicked(index)"
                            type="button" class="list-group-item list-group-item-action" :key="index">
                      {{ company.borrowername }}
                    </button>
                  </div>
                </div>

                <div class="col-md-12 mt-2 mb-2">
                  <label>Company Address</label>
                  <div ref="autocompleteAddress">
                    <!--                    <input id="company_address" v-model="this.company.address" type="text" placeholder="Type in...">-->
                    <VueGoogleAutocomplete id="company_address" classname="form-control form-control-icon"
                                           :placeholder="$t('type_in')"
                                           :class="{'is-invalid': submitted && v$.company.address.$error,}"
                                           @input="updateCompanyAddress"
                                           v-on:placechanged="getAddressData">
                    </VueGoogleAutocomplete>
                    <div v-for="(item, index) in v$.company.address.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mt-6">
                  <label for="company_phone_number">Company Phone Number *</label>
                  <div>
                    <input class="form-control" id="company_phone_number"
                           :class="{'is-invalid': submitted && v$.company.phone_number.$error || !this.isValidCompanyPhoneNumber}"
                           v-model="this.company.phone_number" type="text" placeholder="Type in...">
                    <div v-for="(item, index) in v$.company.phone_number.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                    <div class="red-color">
                      <span v-if="!isValidCompanyPhoneNumber">Phone number is not valid!</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mt-6">
                  <label for="extension_number">Extension Number</label>
                  <div>
                    <input class="form-control" id="extension_number"
                           :class="{'is-invalid': submitted && v$.company.extension_number.$error,}"
                           v-model="this.company.extension_number" type="text" placeholder="Type in...">
                    <div v-for="(item, index) in v$.company.extension_number.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="title mb-2 mt-2">
                W-2 Employee Count
              </div>
              <div class="row mb-2">
                <div class="col-md-3 col-lg-3">
                  <label for="count_in_2021">Average full-time W-2 employee count in 2021</label>
                  <div>
                    <input class="form-control" id="count_in_2021"
                           @input="update2021EmployeeCnt"
                           :class="{'is-invalid': submitted && v$.company.employee_cnt_2021.$error,}"
                           v-model="this.company.employee_cnt_2021" type="text" placeholder="ex. 23">
                    <div v-for="(item, index) in v$.company.employee_cnt_2021.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3  col-lg-3">
                  <label for="count_in_2020">Average full-time W-2 employee count in 2020</label>
                  <div>
                    <input class="form-control" id="count_in_2020"
                           :class="{'is-invalid': submitted && v$.company.employee_cnt_2020.$error,}"
                           @input="update2020EmployeeCnt"
                           v-model="this.company.employee_cnt_2020" type="text" placeholder="ex. 23">
                    <div v-for="(item, index) in v$.company.employee_cnt_2020.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-lg-3">
                  <span class="_600_14">
                    Potential ERC amount based on W-2 employee count*
                  </span>
                  <div class="_600_37">{{ this.usDollar(this.company.erc_amount) }}</div>
                </div>

                <div class="col-md-3 col-lg-3">
                  <span class="_600_14">
                    Possible PPP Loan*
                  </span>
                  <br><br>
                  <div class="_600_37">{{ this.usDollar(this.company.ppp_loan) }}</div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-12 text-center">
                  <button class="submit px-5" @click="onBtnAddClicked">Add</button>
                </div>
              </div>
            </div>

            <div class="body">
              <div class="_600_30 d-flex justify-content-between">
                <span>
                  Total Estimate *
                </span>
                <span>
                  {{ this.usDollar(this.total_estimate) }}
                </span>
              </div>
              <div class="_400_14 mb-3">
                * This is an estimate only based off the employee counts entered and does
                not represent a guarantee of the credit amount you will receive, or that
                you will qualify for the program. PPP funds received will reduce
                the ERC credit available.
              </div>

              <div class="row">
                <div class="col-md-12 text-center">
                  <button class="submit" @click="onSubmitBtnClicked">Submit Application</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 100px"></div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import {mapGetters, mapActions} from "vuex";
import BootStrapAlert from "@/components/utils/Alert";
import btnBack from "@/components/btn-back/btnBack";
import loanApi from "@/apis/loan-api"
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Constants from "../../../utils/constants";
import utilFunc from "../../../utils/functions"

import GlobalFunc from "@/utils/functions";
import {
  required,
  email,
  helpers,
  numeric,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import {
  authMethods,
} from "@/state/helpers";


export default {
  name: "Questionnaire",
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      name: "",
      user_title: "",
      email: "",
      mobile_no: "",
      companies: [], // candidate companies
      added_companies: [],
      company: {
        name: "",
        address: "",
        phone_number: "",
        extension_number: "",
        employee_cnt_2021: "",
        employee_cnt_2020: "",
        erc_amount: 0,
        ppp_loan: 0,
      },
      total_estimate: 0,

      isPlus: true,


      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      com: "",
    }
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage("Name is required", required),
      },
      user_title: {
        required: helpers.withMessage("User Title is required", required),
      },
      mobile_no: {
        required: helpers.withMessage("Mobile No is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      company: {
        name: {
          required: helpers.withMessage("Company Name is required", required),
        },
        address: {
          required: helpers.withMessage("Company Address is required", required),
        },
        phone_number: {
          required: helpers.withMessage("Company Phone Number is required", required),
        },
        extension_number: {
          required: helpers.withMessage("Extension Number is required", required),
        },
        employee_cnt_2021: {
          required: helpers.withMessage("Average full-time W-2 employee count in 2021 is required", required),
          numeric: helpers.withMessage("Please enter a valid number", numeric),
        },
        employee_cnt_2020: {
          required: helpers.withMessage("Average full-time W-2 employee count in 2020 is required", required),
          numeric: helpers.withMessage("Please enter a valid number", numeric),
        },
      },
    }
  },
  methods: {
    ...authMethods,
    ...mapActions("alert", ["setAlert"]),
    usDollar: GlobalFunc.usDollar,
    getOnlyPhoneNumbers: GlobalFunc.getOnlyPhoneNumbers,
    toggleBtn() {
      this.isPlus = !this.isPlus;
    },

    async updateCompanyName(e) {
      this.company.name = e.target.value;
      await loanApi.searchPPP(e.target.value).then((response) => {
        const {status, data} = response;
        if (status === "success") {
          const {companies, company_name} = data;
          if (this.$refs.company_name.value !== company_name) return;
          this.companies = companies;
        }

      }).catch((error) => {
        console.log(error);
        // this.tryingToRegister = false;
        // this.regError = error ? error : "";
        // this.isRegisterError = true;
      }).finally(() => {
        // this.$isLoading(false);
      });
    },

    onItemCompanyClicked(pos) {
      let company = this.companies[pos];
      const {borrowername, borroweraddress, payroll_proceed} = company;
      this.company.name = borrowername;
      this.company.address = borroweraddress;
      this.company.ppp_loan = payroll_proceed ?? 0;

      const autocompleteAddress = this.$refs.autocompleteAddress;
      this.com = autocompleteAddress.querySelector('#company_address');
      this.com.value = this.company.address ?? "";
      this.companies = [];
    },

    onItemCompanyDeleted(pos) {
      this.added_companies = this.added_companies.filter((item, index) => index !== pos);
    },

    onBtnAddClicked() {
      this.submitted = true;

      this.v$.company.$touch();
      if (this.v$.company.$invalid) return;

      // stop here if the company phone number is invalid
      if (!this.isValidCompanyPhoneNumber) return;

      this.added_companies.push({...this.company, phone_number: this.getOnlyPhoneNumbers(this.company.phone_number)});
      this.total_estimate += this.company.erc_amount;

      this.company = {
        name: "",
        address: "",
        phone_number: "",
        extension_number: "",
        employee_cnt_2021: 0,
        employee_cnt_2020: 0,
        erc_amount: 0,
        ppp_loan: 0,
      }
      this.isPlus = true;
    },

    updateCompanyAddress(e) {
      this.company.address = e.target.value;
    },

    async onSubmitBtnClicked() {
      this.submitted = true;
      // stop here if form is invalid

      if (this.isUpperCase) return;
      this.v$.name.$touch();
      if (this.v$.name.$invalid) return;

      this.v$.user_title.$touch();
      if (this.v$.user_title.$invalid) return;

      this.v$.email.$touch();
      if (this.v$.email.$invalid) return;

      this.v$.mobile_no.$touch();
      if (this.v$.mobile_no.$invalid) return;

      // stop here if the phone number is invalid
      if (!this.isValidPhoneNumber) return;

      if (this.added_companies.length < 1) {
        this.setAlert({message: "Please add a company at least.", errors: null, color: "danger"});
        return;
      }
      // add your code here.
      this.$isLoading(true);
      await loanApi.postApplication(this.name, this.email, this.user_title, this.getOnlyPhoneNumbers(this.mobile_no), this.total_estimate, JSON.stringify(this.added_companies)).then((response) => {
        console.log(response);
        const {status, data} = response;
        if (status === "success") {
          const {application_id} = data;
          this.$router.push({
            path: `/application/${application_id}/questionnaire`
          })
        }
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          // Handle 400 error
          const {message} = error.response.data;
          if (message === "JWT Expired") {
            this.logOut();
            this.$router.push({
              name: "login"
            });
          } else
            alert(message);
        } else {
          // Handle other errors
          console.log("An error occurred:", error.message);
        }

      }).finally(() => {
        this.$isLoading(false);
      });
    },

    update2020EmployeeCnt(e) {
      this.company.employee_cnt_2020 = e.target.value;
      this.company.erc_amount = this.company.employee_cnt_2020 * Constants._2020_EMPLOYEE_ERC_AMOUNT
          + this.company.employee_cnt_2021 * Constants._2021_EMPLOYEE_ERC_AMOUNT;
    },
    update2021EmployeeCnt(e) {
      this.company.employee_cnt_2021 = e.target.value;
      this.company.erc_amount = this.company.employee_cnt_2020 * Constants._2020_EMPLOYEE_ERC_AMOUNT
          + this.company.employee_cnt_2021 * Constants._2021_EMPLOYEE_ERC_AMOUNT;
    },

    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {
      console.log(addressData, id);
      const autocompleteAddress = this.$refs.autocompleteAddress;
      this.com = autocompleteAddress.querySelector('#company_address');
      this.company.address = this.com.value;
    },
  },
  computed: {
    isUpperCase() {
      return !Constants.EMAIL_PATTERN.test(this.email);
    },
    ...mapGetters("auth", ["currentUser", "loggedIn"]),
    isValidPhoneNumber() {
      if (!this.mobile_no) return true;
      return utilFunc.validatePhoneNumber(this.mobile_no);
    },
    isValidCompanyPhoneNumber() {
      if (!this.company.phone_number) return true;
      return utilFunc.validatePhoneNumber(this.company.phone_number);
    }
  },
  watch: {},
  mounted() {

  },
  unmounted() {

  },
  created() {

  },
  filters: {},
  components: {
    Layout,
    BootStrapAlert,
    VueGoogleAutocomplete,
    btnBack
  }
}
</script>

<style lang="scss" scoped src="./new-application.scss"></style>